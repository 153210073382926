import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'rc-tooltip/assets/bootstrap.css';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Row, Col } from 'react-bootstrap';
import { setpriceMin, setpriceMax, setfilterData } from '../actions/ProductsAction';
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const Filter = (props) => {

	const [collapsible, setCollapsible] = useState(false);

	const onSliderChange = value => {
		let pricemin = value[0];
		let pricemax = value[1];
		props.setpriceMin(pricemin);
		props.setpriceMax(pricemax);
	};

	const handleChange = (event, type) => {
		let temp = JSON.parse(JSON.stringify(props.filter_data));
		const value = event.target.name;
		for (var i = 0; i < temp[props.type].length; i++) {
			if (temp[props.type][i].name === value) {
				temp[props.type][i].checked = !temp[props.type][i].checked;
				if (props.type === "prices") {
					props.setpriceMin(parseInt(value.split("-")[0]));
					props.setpriceMax(parseInt(value.split("-")[1]));
				}
			}
			else if (type === 'radio')
				temp[props.type][i].checked = false;
		}
		props.setfilterData(temp);
	}

	const displaySlider = () => {

		return (
			<React.Fragment key="test">
				{(typeof props.filters != "undefined" && typeof props.filters.prices != "undefined") 
					? (<div className="slider">
						<Row><b>{props.filters.prices.displayName}</b></Row>
						<br />
						<Row>
							<Range min={props.filters.prices.price.min} 
								   max={props.filters.prices.price.max} 
								   defaultValue={(props.isMobileMenu) 
										   ? ([props.pricemin, props.pricemax]) 
										   : ([props.filters.prices.price.min, props.filters.prices.price.max])} 
								   tipFormatter={value => ` ${value}`} value={[props.pricemin, props.pricemax]} 
								   onChange={onSliderChange} />
						</Row>
							<p>Rs {props.pricemin} - {props.pricemax} </p></div>) 
					: null}
			</React.Fragment>
		);
	}

	const displayFilter = () => {

		let content = [];
		let isprice = false;

		if (props.type === "prices" && typeof props.filters != "undefined" && typeof props.filters.prices != "undefined" && typeof props.filters.prices.price != "array" && props.filters.prices.price.type === "slider") {
			content = [content, displaySlider()];
			isprice = true;
		}
		else if (typeof props.filter_data != "undefined" && typeof props.filter_data[props.type] != "undefined" && typeof props.filters != "undefined" && typeof props.filters[props.type] != "undefined" && typeof props.filters[props.type][props.type.substring(0, props.type.length - 1)] != "undefined") {
			for (var i = 0; i < props.filter_data[props.type].length; i++) {
				let type = props.filters[props.type][props.type.substring(0, props.type.length - 1)][i].type;
				content = [content, (<React.Fragment key="test">
					<Row>
						<Col>
							<input name={props.filter_data[props.type][i].name} 
									type={type} 
									checked={props.filter_data[props.type][i].checked} 
									onChange={(e) => handleChange(e, type)} />
							<span className="pl-2">{props.filter_data[props.type][i].name}</span>
						</Col>
					</Row>
				</React.Fragment>)];
			}
		}

		return (
			<React.Fragment >
				{(content.length > 0 && !isprice) ? (<div className="checkbox_filter"><Row><Col md={1} xs={2} className="p-0"><label className="filter_name"><i className={(collapsible) ? `fa fa-minus` : `fa fa-plus`} style={{ color: "#800000" }} onClick={() => setCollapsible(!collapsible)} ></i></label></Col><Col md={4} xs={3} className="p-0"><label className="filter_name"><b onClick={() => setCollapsible(!collapsible)}>{props.filters[props.type].displayName.concat("  ")}</b></label></Col></Row><div></div>{collapsible ? (<form>{content}</form>) : null}</div>) : null}
				{(content.length > 0 && isprice) ? (content) : null}
			</React.Fragment>
		);
	}

	return <div>{displayFilter()}</div>
}

const mapStateToProps = state => ({
	pricemin: state.ProductsData.pricemin,
	pricemax: state.ProductsData.pricemax,
	filter_data: state.ProductsData.filter_data,
	filters: state.ProductsData.filters,
	isMobileMenu: state.CategoryData.isMobileMenu
});


export default connect(mapStateToProps, { setpriceMin, setpriceMax, setfilterData })(Filter);
