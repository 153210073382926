//.. local run --development 
const company = {
    name: '#',
    logo:'logo.png'
};

const local_app = {
    COMPANY_URL: "stageb.uvanij.com",
    API_URL: "http://localhost:5001/service/",
    IMG_URL: "https://stageb.uvanij.com/images/"
};

const dev_app = {
    COMPANY_URL: "devb2b.wizardcomm.in",
    API_URL: "https://admin.wizardcomm.in/serviceb2b/",
    IMG_URL: "https://devb2b.wizardcomm.in/images/"
};

const stage_app = {
    COMPANY_URL: "stageb.uvanij.com",
    API_URL: "https://stagebadmin.uvanij.com/service/",
    IMG_URL: "https://stageb.uvanij.com/images/"
};

const prod_app = {
    COMPANY_URL: "naumiwholesale.uvanij.com",
    API_URL: "https://stagebadmin.uvanij.com/service/",
    IMG_URL: "https://naumiwholesale.uvanij.com/images/"
};

let configured = {company};

if(process.env.NODE_ENV === 'development'){
    //.. local dev env:: preconfigured
    configured = {...configured, app:dev_app, cacheTimeOut: 10 }; // CHANGE FOR LOCAL DEVELOPMENT
}else{
    //.. get app related details from .env file
    configured = {...configured, app:{
        API_URL: process.env.REACT_APP_API_URL,
        IMG_URL: process.env.REACT_APP_IMG_URL
    },
    cacheTimeOut:parseInt(process.env.REACT_APP_CACHE_INTERVAL) };
}

export const config = configured; 
