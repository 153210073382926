import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Row, Col, Container, Card, Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageUtil from '../../utils/ImageUtil';
import { to2Decimal, formatINR } from '../../utils/CommonUtil';
import ColorCode from '../ColorCode';


let accRefs = []; // accordion reference

function ContextAwareToggle({ children, eventKey, callback }) {

    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
    const isCurrentEventKey = currentEventKey === eventKey;

    accRefs.push({
        click: decoratedOnClick,
        state: isCurrentEventKey
    });

    return (<Button
        type="button" className="float-left border-0 mr-2 mob-acc-button"
        style={{ backgroundColor: '#343A40' }}
        onClick={decoratedOnClick}
    >
        {isCurrentEventKey
            ? <i className="fa fa-minus mt-2 fsize14" aria-hidden="true"></i>
            : <i className="fa fa-plus mt-2 fsize14" aria-hidden="true"></i>}
    </Button>)
}

/**
 * Creates a new OrderedItemTable.
 * @class
 */
function OrderedItemTable(props) {

    const navigate = useNavigate();

    /** @function
     * @name onEdit 
     */
    const onEdit = (item) => {
        navigate(`/products/${item.id}`)
    }

    const goBack = () => {
        navigate(-1);
    }

    //.. toggle states for expand / collapse
    const stateToggle = (toggleState) => {
        for (let i = 0; i < accRefs.length; i++) {
            if (toggleState === !accRefs[i].state) accRefs[i].click()
        }
    }

    /** @function
    *   @name renderTable  
    */
    const renderTable = () => {
        if (props.checkoutDetails && props.checkoutDetails.items) {
            return props.checkoutDetails.items.map((item, i) => {
                return (
                    <Accordion defaultActiveKey={i === 0 ? item.id : ""} key={i} >
                        <Card className="mt-2" key={i}>
                            <Card.Header className="p-0">
                                <ContextAwareToggle eventKey={item.id}></ContextAwareToggle>
                                <Container className="p-2 pl-3 bg-dark text-white font-12-mob">
                                    <Row>
                                        <Col md={8} xs={6} className="p-0"><span className="fsize14"></span>{item.itemCode}
                                            <span className="mr-3 ml-3 fsize14" style={{ cursor: "pointer" }} onClick={() => onEdit(item, i)}>
                                                <span className="hidden-xs"></span> <i className="fa fa-pencil fsize17" aria-hidden="true"></i>
                                            </span>
                                        </Col>
                                        {/* <Col md={2} xs={4} className="p-0 text-left text-right-mob">
                                            <span className="mr-3 ml-3 fsize14" style={{ cursor: "pointer" }} onClick={() => onEdit(item, i)}>
                                                <span className="hidden-xs"></span> <i className="fa fa-pencil fsize17" aria-hidden="true"></i>
                                            </span>
                                        </Col> */}
                                        {/* <Col md={1} xs={6} className="p-0 text-right text-left-mob"><span className="fsize14">Qty: </span>{item.quantity}</Col> */}
                                        <Col md={4} xs={6} className="p-0 text-right"><span className="fsize14">Qty: </span><span className="mr-5">{item.quantity}</span>{formatINR(item.totalAmount)}</Col>
                                    </Row>
                                </Container>
                            </Card.Header>
                            <Accordion.Collapse eventKey={item.id}>
                                <Card.Body className="border-bottom pl-0-mob pr-0-mob border-none-mob pb-0-mob">

                                    <Container fluid key={`table${i}`} className="table mb-0">

                                        <Row className="tr">

                                            <Col className="td border border-none-mob" sm={3}>
                                                <img className="card-img-top" src={item.itemImage ? ImageUtil.getImage(item.itemImage) : ImageUtil.getErrorImage()} /><br />
                                            </Col>

                                            <Col className="td p-0 border-bottom border-right border-top" sm={9}>
                                                <Container className="table mb-0 hidden-xs">
                                                    <Row className="border-bottom border-top bgefefef">
                                                        <Col className="td border-right text-center pb-1 pt-1 text-left"><b>Size</b></Col>

                                                        <Col className="td border-right text-center pb-1 pt-1 text-left"><b>Color</b></Col>
                                                        <Col className="td border-right text-center pb-1 pt-1 text-left"><b>Qty</b></Col>
                                                        <Col className="td border-right pb-1 pt-1 text-center"><b>Rate (₹)</b></Col>
                                                        <Col className="td border-right pb-1 pt-1 text-center"><b>GST ({item.gstPercent})</b></Col>
                                                        <Col className="td pb-1 pt-1 text-right"><b>Amt (₹)</b></Col>
                                                    </Row>
                                                </Container>
                                                {item.orders.map((size, s) => {
                                                    return (
                                                        <Container fluid key={`s${s}`} className="table mb-0">
                                                            <Row className="tr border-bottom hidden-xs" >
                                                                <Col className="td border-right text-center pt-1 pb-1" sm={2}><b>{size.name}</b></Col>
                                                                <Col className="td p-0 pt-1 pb-1" sm={4} >
                                                                    <Container fluid className="table mb-0">
                                                                        {size.colors.map((colored_item, c) => {
                                                                            return (
                                                                                <Row key={`c${c}`} className="tr">
                                                                                    <Col className="td border-right fsize13"><span className="mr-1"><ColorCode color={colored_item.color} />{colored_item.name}</span></Col>
                                                                                    <Col className="td border-right text-center fsize13 pt-1 pt-1">{colored_item.quantity}</Col>
                                                                                </Row>)
                                                                        })}
                                                                    </Container>
                                                                </Col>
                                                                <Col className="td border-right text-center pt-1 pb-1" sm={2}>{size.colors[0].gst}</Col>
                                                                <Col className="td border-right text-center pt-1 pb-1" sm={2}>{size.colors[0].rate}</Col>
                                                                <Col className="td p-0 pt-1 pb-1" sm={2} >
                                                                    <Container fluid className="table mb-0">
                                                                        {size.colors.map((colored_item, c) => {
                                                                            return (
                                                                                <Row key={`c${c}`} className="tr">
                                                                                    <Col className="td text-right fsize13 pt-1 pt-1">{colored_item.amount}</Col>
                                                                                </Row>)
                                                                        })}
                                                                    </Container>
                                                                </Col>
                                                            </Row>
                                                            <div className="hidden-desktop p-2">
                                                                <Row className="bg-secondary text-white">
                                                                    <Col xs={3} className="fsize12 pr-0 pt-2 pb-2">
                                                                        <span className="fsize12 mb-0">Size: </span><b>{size.name}</b>
                                                                    </Col>
                                                                    <Col xs={5} className="fsize12 pr-1 pt-2 pb-2">
                                                                        <span className="fsize12 mb-0">GST({item.gstPercent})</span>: <b>{size.colors[0].gst}</b>
                                                                    </Col>
                                                                    <Col xs={4} className="fsize12 pr-1 pt-2 pb-2">
                                                                        <span className="fsize12 mb-0">Rate: </span><b>{size.colors[0].rate}</b>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="text-dark border-left border-right">
                                                                    <Container>
                                                                        <Row className="border-top">
                                                                            <Col className="border-right text-left">
                                                                                <b>Color</b>
                                                                            </Col>
                                                                            <Col className="border-right text-center">
                                                                                <b>Qty</b>
                                                                            </Col>
                                                                            <Col className="text-right">
                                                                                <b>Amount</b>
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>
                                                                </Row>
                                                                <Row className="border">
                                                                    <Col className="td p-0">
                                                                        <Container fluid className="table mb-0">
                                                                            {size.colors.map((colored_item, c) => {
                                                                                return (
                                                                                    <Row key={`c${c}`} className="tr">
                                                                                        <Col className="td border-right fsize13"><span className="mr-1"><ColorCode color={colored_item.color} />{colored_item.name}</span></Col>
                                                                                        <Col className="td border-right text-center fsize13 pt-1 pt-1">{colored_item.quantity}</Col>
                                                                                        <Col className="td text-right fsize13 pt-1 pt-1">{colored_item.amount}</Col>
                                                                                    </Row>)
                                                                            })}
                                                                        </Container>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Container>)
                                                })}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                )
            })
        } else {
            return null
        }
    }

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col sm={6} xs={4} className="pl-0">
                        <div className="chk-out-button  text-left bx-shadow-withbord">
                            <Link to='/' onClick={goBack} className="product-links-anchor"><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp;Back </Link>
                        </div>
                    </Col>
                    <Col className="text-right pr-0 pl-0-mob" sm={6} xs={8}>

                        <button type="button" className="btn btn-outline-secondary btn-sm mr-3" onClick={() => stateToggle(true)}>Expand All</button>
                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={() => stateToggle(false)}>Collapse All</button>
                    </Col>
                </Row>
            </Container>
            {renderTable()}
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    cartList: state.CartData.cartList,
    checkoutDetails: state.CheckoutData.checkoutDetails,
})

export default connect(mapStateToProps)(OrderedItemTable);




