import React from 'react';
import { connect } from 'react-redux';
import { Col,Button } from 'react-bootstrap';
import {setfilterData,setpriceMin,setpriceMax} from  '../actions/ProductsAction';
import Filter from './Filter.js'

const FilterSection = (props) => {  


const clear=()=>
{
	let temp=JSON.parse(JSON.stringify(props.filter_data));
	var i;
	for(i=0;i<props.filter_data.brands.length;i++)
	{
		temp.brands[i].checked=false;
	}
	for(i=0;i<props.filter_data.colors.length;i++)
	{
		temp.colors[i].checked=false;
	}
	for(i=0;i<props.filter_data.sizes.length;i++)
	{
		temp.sizes[i].checked=false;
	}
	for(i=0;i<props.filter_data.discounts.length;i++)
	{
		temp.discounts[i].checked=false;
	}
	if(typeof props.filters !="undefined" && typeof props.filters.prices != "undefined" && typeof props.filters.prices.price == "array" && props.filters.prices.price[0].type==="radio")
	{
	for( i=0;i<props.filter_data.prices.length;i++)
	{
		temp.prices[i].checked=false;
	}
	props.setpriceMin(0);
	props.setpriceMax(0);
	}
	else if(typeof props.filters !="undefined" && typeof props.filters.prices !="undefined" && typeof props.filters.prices.price != "array" && props.filters.prices.price.type==="slider")
    {
	props.setpriceMin(props.filters.prices.price.min);
	props.setpriceMax(props.filters.prices.price.max);
	}
	props.setfilterData(temp);
}
  const displayFilter = () => {
  return (
        <React.Fragment >
		<Filter type="brands"/>
		<Filter type="colors"/>
		<Filter type="sizes"/>
		<Filter type="discounts"/>
		<Filter type="prices"/>
      </React.Fragment>
	  );
  }
    return (
        <Col md={2}>
            <div className="pr-0 pt-4 pb-4">
                <h5 className="filter-heading border-bottom ">FILTER BY..</h5>
				<div>
				<Button className="clear_filter" variant="link" onClick={()=>clear()}><b>Clear All</b><span>&nbsp;&nbsp;</span><i className="fa fa-times" /></Button>
				{displayFilter()}
			</div>
            </div>
        </Col>
    )

}

const mapStateToProps = state => ({
	pricemin: state.ProductsData.pricemin,
	pricemax:state.ProductsData.pricemax,
	filter_data:state.ProductsData.filter_data,
	filters:state.ProductsData.filters
});


export default connect(mapStateToProps, {setfilterData,setpriceMin,setpriceMax})(FilterSection);
